(function ($) {
  Drupal.behaviors.mppSortV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $sortContainer = $('.js-product-grid-sorted', context);
      var $sortSelect = $('.js-mpp-sort-menu', context);
      var $grids = $('.product-grid', context);

      if (!$sortSelect.length) {
        return;
      }

      // Loop through each grid, apply minimum price as data attribute for sorting
      $grids.each(function () {
        var $productItems = $('.js-product-grid-item:not(.js-product-grid-item-tout)', $(this));

        $productItems.each(function (index) {
          // Get this product ID and data
          var prodID = $(this).attr('data-product-id');
          var productData = page_data['product-input-type']
            ? page_data['product-input-type']['products'][index]
            : page_data['catalog-mpp']['categories'][0]['products'][index];

          // Check if a price-sort is already set, and if so override it by determining the lowest price inside of the skus array
          if (!_.isUndefined($(this).attr('data-price-sort')) && !_.isEmpty(productData)) {
            // http://stackoverflow.com/a/15851531
            var minPrice = Math.min.apply(null, _.pluck(productData.skus, 'PRICE'));

            if (minPrice) {
              $(this).attr('data-price-sort', minPrice);
            }
          } else {
            console.log('fail');
          }
        });
      });

      $sortSelect.on('change', function () {
        $sortContainer.mixItUp('sort', this.value);
      });

      $sortContainer.each(function () {
        var $productGrid = $(this).closest('.js-product-grid');
        var hasQuickshop = $productGrid.hasClass('product-grid--quickshop');

        // instantiate mixitup
        $(this)
          .addClass('mixitup-initialized')
          .mixItUp({
            selectors: {
              target: '.js-product-grid-item--sortable',
              sort: '.js-mpp-sort-option'
            },
            layout: {
              display: 'flex'
            },
            callbacks: {
              onMixStart: function () {
                // Destroy quickshop:
                if (hasQuickshop) {
                  $productGrid.trigger('product.quickshop.reset');
                }
              },
              onMixEnd: function () {
                // Rebuild equal heights:
                $productGrid.trigger('grid.reflow');

                // Rebuild quickshop:
                if (hasQuickshop) {
                  $productGrid.trigger('product.quickshop.init');
                }
              }
            }
          });
      });
    },
    attached: false
  };
})(jQuery);
